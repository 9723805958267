import React from "react";
import Flag from "react-world-flags";
import { Container, Card, Row, Button } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";

import { whatsapp } from "./../utilities/settings";
import { priceCarousel } from "./../utilities/carousels";

import data from "./../data/harga.json";

const convertRupiah = require("rupiah-format");

const Harga = () => {
  return (
    <section id="harga" className="widget">
      <Container>
        <div className="section-title">
          Harga <h3 className="seo fw-bolder">Jasa Penerjemah</h3> Trans Express
        </div>

        {pricelist(data.non_tersumpah.reguler, "Non Tersumpah", "Reguler")}
        {pricelist(data.non_tersumpah.kilat, "Non Tersumpah", "Kilat")}
        {pricelist(data.tersumpah.reguler, "Tersumpah", "Reguler")}
        {pricelist(data.tersumpah.kilat, "Tersumpah", "Kilat")}
      </Container>
    </section>
  );
};

export default Harga;
function pricelist(data, kategori, subkategori) {
  return (
    <div className="category">
      <h4 className="my-3">
        {kategori} ({subkategori})
      </h4>
      <Row>
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          nav
          {...priceCarousel}
        >
          {data.map((x, i) => {
            return x.promo ? (
              <Card className="mb-4" data-ribbon="Promo" key={i}>
                <Card.Body>
                  <p className="title">{x.bahasa}</p>
                  <small>Start from</small>

                  <p className="price slash">
                    {convertRupiah.convert(x.harga)}/lbr
                  </p>

                  <p className="price promo">
                    {convertRupiah.convert(x.promo)}/lbr
                  </p>
                  <div className="flag">
                    <Flag className="icon-flag" code={x.country_code} />
                  </div>
                  <br />
                  <br />
                  <Button href={whatsapp}>Order</Button>
                </Card.Body>
              </Card>
            ) : (
              <Card className="mb-4" key={i}>
                <Card.Body>
                  <p className="title">{x.bahasa}</p>
                  <small>Start from</small>
                  <p className="price ">{convertRupiah.convert(x.harga)}/lbr</p>
                  <div className="flag">
                    <Flag className="icon-flag" code={x.country_code} />
                  </div>
                  <br />
                  <br />
                  <Button href={whatsapp}>Order</Button>
                </Card.Body>
              </Card>
            );
          })}
        </OwlCarousel>
      </Row>
    </div>
  );
}
