import React from "react";
import { Routes, Route } from "react-router-dom";
import Faq from "./pages/Faq";
import Fitur from "./pages/Fitur";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Klien from "./pages/Klien";
import Kontak from "./pages/Kontak";
import Harga from "./pages/Harga";

const Routing = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/fitur" element={<Fitur />} />
      <Route path="/klien" element={<Klien />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/kontak" element={<Kontak />} />
      <Route path="/harga" element={<Harga />} />
    </Routes>
  );
};

export default Routing;
