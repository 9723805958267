export const name = "Trans Express";
export const seo = "Jasa Penerjemah Ekspres Kilat Murah";
export const slogan = "The Best Urgent Translation Service";
export const company = "PT. Translasi Media Ekspres";
export const description = "Jasa Penerjemah Trans Express";

export const menu1 = "Home";
export const menu2 = "Fitur";
export const menu3 = "Harga";
export const menu4 = "Klien";
export const menu5 = "Faq";
export const menu6 = "Kontak";

export const twitter = "https://twitter.com/penerjemahxpres";
export const facebook = "https://www.facebook.com/penerjemahekspres";
export const instagram = "https://www.instagram.com/penerjemah.ekspres/";
export const whatsapp =
  "https://api.whatsapp.com/send?phone=6282244441150&text=Hi%21%20I%20am%20interested%20in%20your%20translation%20service";
