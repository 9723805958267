import React from "react";
import Banner from "../components/banner/Banner";
import Translate from "../components/translate/Translate";
import Clients from "../components/clients/Clients";
import Questions from "../components/questions/Questions";
import Features from "../components/features/Features";
import Harga from "../components/harga/Harga";
import Testimonials from "../components/testimonials/Testimonials";

const Home = () => {
  return (
    <div id="home">
      <Banner />
      <Translate />
      <Clients isHome={true} />
      <Features isHome={true} />
      <Harga isHome={true} />
      <Testimonials />

      <Questions />
    </div>
  );
};

export default Home;
