import React from "react";

import Clients from "../components/clients/Clients";
import Testimonials from "../components/testimonials/Testimonials";

const Klien = () => {
  return (
    <div>
      <Clients isHome={false} />
      <Testimonials />
    </div>
  );
};

export default Klien;
