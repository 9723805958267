import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Animated } from "react-animated-css";

import { company } from "../../utilities/settings";

import "./Banner.scss";

const Banner = () => {
  return (
    <Animated animationIn="fadeInLeft" isVisible={true}>
      <section id="banner">
        <div id="hero">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <p className="title d-inline">
                  Halo, kami adalah {company}, Biro{" "}
                </p>
                <h2 className="seo d-inline">
                  <strong>Jasa Penerjemah</strong>
                </h2>
                <p className="title d-inline"> Trans Express</p>
                <br />
                <br />
                <div className="description">
                  PT. Translasi Media Ekspres adalah sebuah tim penerjemah yang
                  menyediakan <h5 className="seo">jasa penerjemahan</h5> yang
                  cepat, handal dan bisa diandalkan. Kami akan membantu Anda
                  <h6 className="seo">menerjemahkan</h6> dokumen/file dalam
                  jangka waktu singkat dengan tarif yang sangat terjangkau.
                  Kapan pun dan di mana pun Anda berada, tim kami siap membantu{" "}
                  <h6 className="seo">menerjemahkan</h6> dokumen Anda.
                </div>
              </Col>
              <Col md={6}>
                <Image
                  src={require(`./../../images/people.png`)}
                  alt="jasa penerjemah trans express"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Animated>
  );
};

export default Banner;
