import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Image from "react-image-webp";

import clientCarousel from "../../utilities/carousels";

import "./Clients.scss";

const Clients = (props) => {
  const arr = [...Array(45).keys()];
  const isHome = props.isHome;

  if (isHome) {
    return (
      <section id="clients" className="widget">
        <Container>
          <Row>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              {...clientCarousel}
            >
              {arr.map((x, i) => (
                <div key={i} className="item">
                  <Image
                    src={require(`./../../images/clients/png/logo-klien-${i}.png`)}
                    webp={require(`./../../images/clients/webp/logo-klien-${i}.webp`)}
                    alt="klien jasa penerjemah trans express"
                  />
                </div>
              ))}
            </OwlCarousel>
          </Row>
        </Container>
      </section>
    );
  }
  return (
    <section id="clients" className="page">
      <Container>
        <p className="section-title">
          Klien <h4 className="seo fw-bolder">Jasa Penerjemah</h4> Trans Express
        </p>
        <Row>
          {arr.map((x, i) => (
            <Col key={i} xs={4} md={3} lg={2}>
              <div className="item">
                <Image
                  src={require(`./../../images/clients/png/logo-klien-${i}.png`)}
                  webp={require(`./../../images/clients/webp/logo-klien-${i}.webp`)}
                  alt="klien jasa penerjemah trans express"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Clients;
