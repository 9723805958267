import React from "react";
import { company, description } from "../../utilities/settings";

const Address = () => {
  return (
    <div>
      <p className="section-title">Hubungi Kami</p>
      <strong>{company}</strong>
      <p>
        {description}
        <br />
        Cimanggis, Depok,
        <br />
        Jawa Barat, Indonesia
        <br />
        <span className="reverse">0511-4444-2280</span>
        <br />
        <span className="reverse">moc.liamg@serpske.hamejrenep</span>
      </p>
    </div>
  );
};

export default Address;
