const FaqData = {
  title: "Tanya Jawab Jasa Penerjemah Trans Express",
  rows: [
    {
      title: "Bagaimana perhitungan jasa terjemahan dokumen?",
      content: `Kami menerapkan tarif flat untuk berbagai jenis dokumen, baik surat, dokumen hukum, teknis, kedokteran, akademis, dll. sebagai berikut:
      Harga mulai Rp. 25.000 / lembar halaman hasil dalam format terjemahan kami: ukuran kertas A4, huruf Courier New 12 pt, spasi double dengan margin 1”.
      Jika dokumen berbentuk file lokalisasi, power point, excel atau format lainnya yang menyulitkan perhitungan berdasarkan jumlah halaman, tarif yang ditetapkan adalah tarif lokalisasi (Harga mulai Rp. 200/kata).`,
    },
    {
      title:
        "Apakah ada tarif minimal yang harus saya bayar untuk terjemahan yang hanya beberapa kata / kalimat?",
      content:
        "Kami menerapkan tarif minimal 1 halaman A4 untuk setiap dokumen yang diterjemahkan.",
    },
    {
      title: "Format file apa yang bisa diterjemahkan?",
      content: `Saat ini kami dapat menerjemahkan semua format file, contohnya: 
      Adobe Framemaker (fm, .mif),
      Adobe InDesign (.inx, .idml, .ixml),
      Character delimited Text files: (.txt, .csv),
      HTML: (.htm, .html),
      Microsoft Excel: (.xls, .xlt, .xlsm, .xlsx, .xltx),
      Microsoft PowerPoint: (.pot, .pps, .ppt, .potm, .potx, .ppsm, .ppsx, .pptm, .pptx),
      Microsoft Word: (.doc, .dot, .docx, .dotx, .docm, .dotm),
      OpenOffice: (.odp, .ods, .odt, .sxw, .sxc, .sxi),
      PDF: (.pdf),
      RTF: (.rtf),
      WorldServer: (.xlf),
      XHTML: (.xhtml),
      XLIFF: (.xliff),
      XML: (.xml),
      Microsoft . NET: (.resx),
      OASIS DITA: (.dita),
      Images: (.jpeg, .jpg, .png)`,
    },

    {
      title:
        "Apakah saya bisa menyerahkan / menerima terjemahan dalam bentuk hard copy?",
      content:
        "Bisa. Dokumen hard copy dapat diantar melalui Grab Express, Go-Send, JNE, Paxel, dll. Namun akan dikenakan biaya tambahan. Silahkan menghubungi kami untuk mengetahui informasi tarif lebih lanjut.",
    },

    {
      title: "Apakah Trans eXpress menyediakan layanan tersumpah?",
      content:
        "Kami menyediakan jasa terjemahan tersumpah ekspres. Perlu Anda ketahui bahwa tidak semua dokumen harus diterjemahkan oleh penerjemah tersumpah. Umumnya, hanya dokumen yang perlu dilegalisir oleh badan pemerintah atau badan pendidikan yang harus memiliki cap penerjemah tersumpah.",
    },
    {
      title:
        "Jika saya tidak puas dengan hasil terjemahan, apakah saya bisa meminta refund?",
      content:
        "Kami tidak dapat mengembalikan uang untuk hasil terjemahan yang sudah berada di tangan klien, tapi kami menyediakan revisi dalam jumlah yang tidak terbatas.",
    },

    {
      title: "Jenis pembayaran apa yang Anda terima?",
      content:
        "Saat ini, Trans eXpress menerima metode pembayaran transfer antar bank (BCA, BNI, Mandiri)",
    },
  ],
};

export default FaqData;
