import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Faq from "react-faq-component";
import ScrollAnimation from "react-animate-on-scroll";

import FaqData from "../../data/faq";

import "./Questions.scss";

const Questions = () => {
  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <section id="questions" className="widget">
        <Container>
          <Faq data={FaqData} />
        </Container>
      </section>
    </ScrollAnimation>
  );
};

Questions.propTypes = {
  uniqueClass: PropTypes.string,
};

export default Questions;
