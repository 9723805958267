import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Address from "../components/address/Address";
import Map from "../components/map/Map";

const Kontak = () => {
  return (
    <Container className="widget">
      <Row>
        <Col md={4}>
          <Address />
        </Col>
        <Col md={8}>
          <Map />
        </Col>
      </Row>
    </Container>
  );
};

export default Kontak;
