import React from "react";
import { Container, Card } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import ScrollAnimation from "react-animate-on-scroll";
import LinesEllipsis from "react-lines-ellipsis";

import { testimonialsCarousel } from "../../utilities/carousels";

import "./Testimonials.scss";

const Testimonials = () => {
  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <section id="testimonials" className="widget">
        <Container>
          <div className="section-title">
            Testimoni Pelanggan{" "}
            <h3 className="seo fw-bolder">Jasa Penerjemah</h3> Trans Express
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            {...testimonialsCarousel}
          >
            <Card className="testimoni">
              <Card.Body>
                <div className="image"></div>
                <p className="name">Rahma I</p>
                <p className="city">Yogyakarta</p>
                <LinesEllipsis
                  maxLine="5"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  text="TransExpress benar-benar the best! Saya perlu menerjemahkan
                disertasi saya over the weekend. Untungnya saya diperkenalkan
                dengan jasa ini yang bisa menerjemahkan hanya
                dalam waktu 2 hari. Saya dan dosen pembimbing saya puas dengan
                kualitas penerjemahan yang tanpa typo atau spelling error,
                padahal dikerjakan dengan kilat. Terima kasih ya,
                bulan depan saya bisa wisuda dengan bangga!"
                ></LinesEllipsis>
              </Card.Body>
            </Card>

            <Card className="testimoni">
              <Card.Body>
                <div className="image"></div>
                <p className="name">Armin S</p>
                <p className="city">Jakarta</p>
                <LinesEllipsis
                  maxLine="5"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  text="Minggu lalu atasan saya mendadak diminta untuk berpidato dalam bahasa Inggris di acara resmi yang dihadiri pejabat-pejabat pemerintah dan duta besar dari negara sahabat. Jam 10 pagi saya langsung call penerjemah ekspres untuk menerjemahkan pidato 2 halaman tersebut agar hasil terjemahannya sudah siap di jam makan siang. Ternyata sebelum jam 12 hasilnya sudah ada di tangan saya. Saya lega, atasan pun senang. Mantap!"
                ></LinesEllipsis>
              </Card.Body>
            </Card>

            <Card className="testimoni">
              <Card.Body>
                <div className="image"></div>
                <span className="name">Michael</span>
                <span className="city">Jakarta</span>
                <LinesEllipsis
                  maxLine="5"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  text="Awalnya saya ragu dengan kualitas terjemahan kilat, apalagi file urgent saya ini jurnal kedokteran. Ternyata Trans bisa menyelesaikan 30+ halaman hanya dalam waktu kurang dari 24 jam, dengan tarif yang bahkan lebih murah dari rata-rata biro penerjemah lainnya. Transaksinya mulus, hasilnya rapi, dan bebas revisi tanpa batas. Puas deh dengan servicenya!"
                ></LinesEllipsis>
              </Card.Body>
            </Card>
          </OwlCarousel>
        </Container>
      </section>
    </ScrollAnimation>
  );
};

export default Testimonials;
