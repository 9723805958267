import React from "react";
import { Container, Card, Image, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
// import { Animated } from "react-animated-css";
import ScrollAnimation from "react-animate-on-scroll";

import { featuresCarousel } from "../../utilities/carousels";

import "./Features.scss";

import jaminan from "./../../images/features/jaminan.jpeg";
import keahlian from "./../../images/features/keahlian.jpeg";
import kualitas from "./../../images/features/kualitas.jpeg";
import manusia from "./../../images/features/manusia.jpeg";
import rahasia from "./../../images/features/rahasia.jpeg";
import price from "./../../images/features/price.jpeg";

const Features = (props) => {
  const isHome = props.isHome;
  if (isHome) {
    return (
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <section id="features" className="widget">
          <Container>
            <div className="section-title">
              Mengapa memilih <h3 className="seo fw-bolder">Jasa Penerjemah</h3>{" "}
              Trans Express
            </div>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              {...featuresCarousel}
            >
              <div className="flip-card">
                <div id="jaminan" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">Jaminan Tepat Waktu</div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">Jaminan Tepat Waktu</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div id="keahlian" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">Pengalaman</div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">Pengalaman</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div id="kualitas" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">Kualitas</div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">Kualitas</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div id="manusia" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">
                        100% Hasil <h6 className="seo">Terjemahan</h6> Manusia
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">
                        100% Hasil <h6 className="seo">Terjemahan</h6> Manusia
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div id="rahasia" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">Jaminan Kerahasiaan</div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">Jaminan Kerahasiaan</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flip-card">
                <div id="price" className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="info">
                      <div className="title">Harga Terjangkau</div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="info">
                      <div className="title">Harga Terjangkau</div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </Container>
        </section>
      </ScrollAnimation>
    );
  } else {
    return (
      <Container>
        <ScrollAnimation animateIn="fadeInUp">
          <section id="features" className="page">
            <div className="section-title">
              Fitur <h4 className="seo fw-bolder">Jasa Penerjemah</h4> Trans
              Express
            </div>

            <Row xs={1} sm={2} md={3} lg={3} xl={4} className="g-4">
              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={jaminan}
                    alt="Jaminan Tenggat Waktu Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">Jaminan Tenggat Waktu</p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Kami menjamin bahwa tim{" "}
                        <h5 className="seo">jasa penerjemah</h5> kami dapat
                        menyerahkan dokumen hasil terjemahan sesuai dengan
                        tenggat waktu yang telah disetujui. Jika ada
                        keterlambatan yang bukan akibat Keadaan Kahar, Klien
                        berhak meminta kompensasi berupa diskon sebesar maksimal
                        50% dari total biaya <h6 className="seo">terjemahan</h6>
                        .
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={keahlian}
                    alt="Keahlian Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">Pengalaman</p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Semua dokumen / file yang dikirim kepada kami dikerjakan
                        oleh tim <h5 className="seo">jasa penerjemah</h5>{" "}
                        berpengalaman (15 tahun di bidangnya) dengan portfolio
                        yang ekstensif, mulai dari akta notaris, laporan
                        keuangan perusahaan multinasional, jurnal ilmiah, buku
                        petunjuk produk-produk mekanis, berbagai jenis kontrak
                        dan perjanjian hingga lokalisasi situs web.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={kualitas}
                    alt="Keahlian Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">Kualitas</p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Semua hasil <h6 className="seo">terjemahan</h6> akan
                        di-proofread sebelum diserahkan kepada klien, dan klien
                        berhak atas revisi setelah hasil{" "}
                        <h6 className="seo">terjemahan</h6> diberikan. Ini
                        adalah nilai tambahan yang kami berikan kepada klien
                        secara Cuma-Cuma sebagai bukti dedikasi kami terhadap
                        kualitas
                        <h5 className="seo">jasa penerjemah</h5> Trans eXpress.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={manusia}
                    alt="Hasil Terjemahan Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">
                        100% Hasil Terjemahan Manusia
                      </p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Semua dokumen / file diterjemahkan secara manual oleh
                        tim
                        <h5 className="seo">jasa penerjemah</h5> kami, tidak
                        menggunakan translation program/tool untuk meminimalisir
                        kesalahan idiom dan memberikan sentuhan kesusastraan
                        yang tidak dapat diberikan oleh suatu program.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={rahasia}
                    alt="Jaminan Kerahasiaan Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">Jaminan Kerahasiaan</p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Tim <h5 className="seo">jasa penerjemah</h5> kami
                        menjamin kerahasiaan semua dokumen / file Anda serta
                        privasi Anda sebagai klien kami.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="d-flex align-items-stretch">
                <Card>
                  <Card.Img
                    variant="top"
                    src={price}
                    alt="Harga Jasa Penerjemah Trans Express"
                  />
                  <Card.Body>
                    <Card.Title>
                      <p className="section-title">Harga Terjangkau</p>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        Trans Express adalah satu-satunya biro{" "}
                        <h5 className="seo">jasa penerjemah</h5>
                        khusus ekspres dengan tarif yang terjangkau. Tarif
                        ekspres kami setara dengan tarif kecepatan{" "}
                        <h5 className="seo">jasa penerjemah</h5> normal di
                        biro-biro lainnya. Kami juga tidak memberikan estimasi,
                        melainkan harga tetap sejak awal sehingga Anda sudah
                        tahu biaya yang harus Anda bayar untuk keseluruhan
                        dokumen.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
        </ScrollAnimation>
      </Container>
    );
  }
};

export default Features;
