import React, { useState } from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";

import {
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
  menu6,
  whatsapp,
} from "../../utilities/settings";

import "./Header.scss";

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  return (
    <>
      <header>
        <Container>
          <Row className="align-items-center">
            <Col xs={10} md={6} lg={5} xl={4} className="logo">
              <a href="/">
                <p className="logo-text">
                  <span>Trans</span> eXpress
                </p>
              </a>
            </Col>
            <Col lg={7} xl={5} className="menu d-none d-lg-block">
              <Nav className="justify-content-center float-lg-end float-xl-center">
                <Nav.Item>
                  <Nav.Link href="/">{menu1}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/fitur">{menu2}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/harga">{menu3}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/klien">{menu4}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/faq">{menu5}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/kontak">{menu6}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={3} className="cta d-none d-xl-block">
              <Button className="float-end" href={whatsapp}>
                <ion-icon name="logo-whatsapp"></ion-icon> Hubungi
              </Button>
            </Col>
            <Col xs={2} md={6} className="d-lg-none text-end">
              <i className="fa-solid fa-bars sidebar" onClick={toggle}></i>
            </Col>
          </Row>
        </Container>
      </header>
      {isOpened && (
        <div className="open-sidebar">
          <i className="fa-solid fa-close close" onClick={toggle}></i>
          <Nav.Item>
            <Nav.Link href="/">{menu1}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/fitur">{menu2}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/harga">{menu3}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/klien">{menu4}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/faq">{menu5}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/kontak">{menu6}</Nav.Link>
          </Nav.Item>
        </div>
      )}
    </>
  );
};

export default Header;
