import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { name, seo, company } from "../../utilities/settings";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md={12} className="text-center mb-3"></Col>
          <Col xl={5}>
            <div className="text-center text-xl-start">
              Hak Cipta © 2020 - {company}
              <br />
              {name} - <h1 className="seo">{seo}</h1>
            </div>
          </Col>
          <Col xl={7} className="payment text-end">
            <div className="icon-bank">
              <Image
                src={require(`./../../images/bank/png/bca.png`)}
                webp={require(`./../../images/bank/webp/bca.webp`)}
                alt="metode pembayaran jasa penerjemah trans express"
              />
            </div>
            <div className="icon-bank">
              <Image
                src={require(`./../../images/bank/png/bni.png`)}
                webp={require(`./../../images/bank/webp/bni.webp`)}
                alt="metode pembayaran jasa penerjemah trans express"
              />
            </div>
            <div className="icon-bank">
              <Image
                src={require(`./../../images/bank/png/mandiri.png`)}
                webp={require(`./../../images/bank/webp/mandiri.webp`)}
                alt="metode pembayaran jasa penerjemah trans express"
              />
            </div>
            <div className="icon-bank">
              <Image
                src={require(`./../../images/bank/png/cimb.png`)}
                webp={require(`./../../images/bank/webp/cimb.webp`)}
                alt="metode pembayaran jasa penerjemah trans express"
              />
            </div>
            <div className="icon-bank">
              <Image
                src={require(`./../../images/bank/png/danamon.png`)}
                webp={require(`./../../images/bank/webp/danamon.webp`)}
                alt="metode pembayaran jasa penerjemah trans express"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
