import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-tooltip/dist/react-tooltip.css";
import "./styles/global.scss";

import Routing from "./Routing";
import Header from "./components/header/Header";
import Kontak from "./components/kontak/Kontak";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Routing />
      <Kontak />
      <Footer />
    </div>
  );
}

export default App;
