import React from "react";
import { Container } from "react-bootstrap";

const NotFound = () => {
  return (
    <section id="notfound" className="page">
      <Container>
        <p className="section-title">Page Not Found</p>
      </Container>
    </section>
  );
};

export default NotFound;
