import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import $ from "jquery";
import _ from "lodash";

import data from "./../../data/harga.json";

import "./Translate.scss";

const Translate = () => {
  const handleSelect = () => {
    $("#from").on("change", function () {
      let fromValue = $("#from").val();
      let options = $("#to option").show();
      if ($(this).val() !== "") {
        options.siblings('option[value="' + fromValue + '"]').hide();
      }
    });
    $("#to").on("change", function () {
      let toValue = $("#to").val();
      let options = $("#from option").show();
      if ($(this).val() !== "") {
        options.siblings('option[value="' + toValue + '"]').hide();
      }
    });
  };

  var groupBy = _.groupBy(data, "Inggris");

  console.log(groupBy);

  useEffect(() => {
    handleSelect();
  });

  return (
    <Container>
      <div id="translate" className="widget">
        <Row>
          <div className="title">
            Saya ingin <h6 className="seo">menerjemahkan</h6> dokumen...
          </div>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Form.Select id="from" defaultValue={"default"}>
              <option value="default" disabled>
                Dari Bahasa
              </option>
              <option value="Inggris">Inggris</option>
              <option value="Prancis">Prancis</option>
              <option value="Italia">Italia</option>
              <option value="Mandarin">Mandarin</option>
              <option value="Jepang">Jepang</option>
              <option value="Indonesia">Indonesia</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Select id="to" defaultValue={"default"}>
              <option value="default" disabled>
                Ke Bahasa
              </option>
              <option value="Inggris">Inggris</option>
              <option value="Prancis">Prancis</option>
              <option value="Italia">Italia</option>
              <option value="Mandarin">Mandarin</option>
              <option value="Jepang">Jepang</option>
              <option value="Indonesia">Indonesia</option>
            </Form.Select>
          </Col>
          <Col>
            <Button className="cek-harga" href="/harga">
              Cek <span className="d-none d-lg-inline-block">Harga</span>
            </Button>
          </Col>
        </Row>
      </div>{" "}
    </Container>
  );
};

export default Translate;
