import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

import "./Kontak.scss";

const Kontak = () => {
  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce>
      <section id="kontak" className="widget">
        <Container>
          <div className="box">
            <Row>
              <div>
                Terima kasih telah mengunjungi situs{" "}
                <h2 className="seo">Jasa Penerjemah</h2> Trans Express. Kami
                sangat menghargai komentar atau saran Anda agar kami dapat
                memberikan pelayanan terbaik. Jika anda memiliki pertanyaan,
                silahkan hubungi kami:
              </div>
            </Row>
            <br />

            <Row>
              <Col md={6} className="mb-2 mb-xl-0">
                <ion-icon name="globe-outline"></ion-icon>
                <span>www.penerjemah-ekspres.com</span>
              </Col>
              <Col md={6} className="mb-2 mb-xl-0">
                <ion-icon name="mail-outline"></ion-icon>
                <span className="reverse">moc.liamg@serpske.hamejrenep</span>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </ScrollAnimation>
  );
};

export default Kontak;
