import React from "react";

const Map = () => {
  return (
    <>
      <div className="section-title mt-4 mt-md-0">
        Lokasi <h4 className="seo fw-bolder">Jasa Penerjemah</h4> Trans Express
      </div>
      <iframe
        title="maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31721.64369096185!2d106.85725640665927!3d-6.367451054181482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ec8fbc857d93%3A0x57ebdaaddab11290!2sKec.%20Cimanggis%2C%20Kota%20Depok%2C%20Jawa%20Barat!5e0!3m2!1sid!2sid!4v1675000797868!5m2!1sid!2sid"
        width="100%"
        height="400"
        loading="lazy"
      ></iframe>
    </>
  );
};

export default Map;
