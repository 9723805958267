export const clientCarousel = {
  loop: true,
  margin: 30,
  nav: false,
  dots: false,
  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],
  items: 1,
  responsive: {
    0: {
      items: 3,
      margin: 15,
      nav: false,
    },
    576: {
      items: 4,
      margin: 20,
      nav: false,
    },
    768: {
      items: 5,
      margin: 25,
      nav: false,
    },
    992: {
      items: 6,
      margin: 15,
      nav: false,
    },
    1200: {
      items: 9,
      margin: 30,
    },
  },
};

export const featuresCarousel = {
  loop: false,
  margin: 30,
  nav: false,

  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],

  items: 1,
  responsive: {
    0: {
      items: 3,
      margin: 15,
      nav: false,
      dots: true,
    },
    576: {
      items: 3,
      margin: 20,
      nav: false,
      dots: true,
    },
    768: {
      items: 4,
      margin: 25,
      nav: false,
      dots: true,
    },
    992: {
      items: 5,
      margin: 15,
      nav: false,
      dots: true,
    },
    1200: {
      items: 6,
      margin: 30,
    },
  },
};

export const priceCarousel = {
  loop: false,
  margin: 30,
  nav: false,

  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],

  items: 1,
  responsive: {
    0: {
      items: 1,
      margin: 15,
      nav: false,
      dots: true,
    },
    576: {
      items: 2,
      margin: 20,
      nav: false,
      dots: true,
    },
    768: {
      items: 2,
      margin: 25,
      nav: false,
      dots: true,
    },
    992: {
      items: 3,
      margin: 15,
      nav: false,
      dots: true,
    },
    1200: {
      items: 4,
      margin: 30,
    },
  },
};

export const testimonialsCarousel = {
  loop: false,
  margin: 30,
  nav: false,
  dots: false,
  navText: [
    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  ],

  items: 2,
  responsive: {
    0: {
      items: 1,
      margin: 15,
      nav: false,
      dots: true,
    },
    576: {
      items: 2,
      margin: 20,
      nav: false,
      dots: true,
    },
    768: {
      items: 2,
      margin: 25,
      nav: false,
      dots: true,
    },
    992: {
      items: 3,
      margin: 15,
      nav: false,
    },
    1200: {
      items: 3,
      margin: 30,
    },
  },
};

export default clientCarousel;
