import React from "react";
import Flag from "react-world-flags";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

import "./Harga.scss";

const Harga = () => {
  return (
    <ScrollAnimation animateIn="fadeInLeft" animateOnce>
      <section id="harga" className="widget">
        <Container>
          <div className="section-title">
            Harga <h3 className="seo fw-bolder">Jasa Penerjemah</h3> Trans
            Express
          </div>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <p className="title">Terjemahan Non Tersumpah</p>
                  <small>Harga mulai</small>
                  <p className="price">Rp. 25.000/lbr</p>
                  <div className="flag">
                    <Flag className="icon-flag" code="gb" />
                  </div>
                  <div className="flag">
                    <Flag className="icon-flag" code="fr" />
                  </div>
                  <div className="flag">
                    <Flag className="icon-flag" code="it" />
                  </div>
                  <div className="flag">
                    <Flag className="icon-flag" code="cn" />
                  </div>
                  <div className="flag">
                    <Flag className="icon-flag" code="jp" />
                  </div>
                  <div className="flag">
                    <Flag className="icon-flag" code="kr" />
                  </div>
                  <br />
                  <br />
                  <Button href="/harga">Info</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <p className="title">Terjemahan Tersumpah</p>
                  <small>Harga mulai</small>
                  <p className="price">Rp. 50.000/lbr</p>
                  <div className="flag">
                    <Flag className="icon-flag" code="gb" />
                  </div>
                  <br />
                  <br />
                  <Button href="/harga">Info</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </ScrollAnimation>
  );
};

export default Harga;
